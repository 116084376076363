import { CurrencyCode, LanguageCode, RangeValue } from "../../../utils/Utils";
import { parseJSON, decodeBase64 } from "../../../utils/funtionCommon";
import { Buffer } from 'buffer';

export default interface IConverterParameters {

  getAmount(): number;

  getSqm(): number;

  getCo2(): number;

  getArea(): string;

  getCampaign(): string;

  getInterval(): number;

  getLandCode(): string;

  getLand(): any;

  setLand(land: any): void;

  getStatus(): string;

  getCurrency(): CurrencyCode;

  getLanguage(): LanguageCode;

  setRegion(region: any): void;
}

const isBrowser = () => typeof localStorage !== "undefined";

export default class ConverterParameters implements IConverterParameters {
  private amount: number;
  private sqm: number;
  private co2: number;
  private area: string;
  private interval: number;
  private landCode: string;
  private campaignCode: string;
  private land: any;
  private status: string;
  private couponCode: string;
  private currency: CurrencyCode;
  private language: LanguageCode;
  private tag: string;
  private rhythm: string;
  private goal: string;
  private source: string;
  private config: any;
  private submissionId: string;
  private regionCode: string;
  private region: any;
  constructor(location: string) {
    const parameters = new URLSearchParams(location);
    
    this.amount = parseInt(parameters.get("amount"));
    this.sqm = parseInt(parameters.get("sqm"));
    this.co2 = parseInt(parameters.get("co2"));
    this.interval = parseInt(parameters.get("interval"));
    this.couponCode = parameters.get("code");
    this.language = parameters.get("lang")?.toUpperCase() as LanguageCode;
    this.currency = parameters.get("currency")?.toUpperCase() as CurrencyCode;
    this.landCode = parameters.get("area");
    this.regionCode = parameters.get("region");
    this.campaignCode = parameters.get("campaign");
    this.tag = parameters.get("tag");
    this.rhythm = parameters.get("rhythm");
    this.goal = parameters.get("goal");
    this.submissionId = parameters.get("submission-id");
    this.source = parameters.get("source");
    this.config = parameters.get("config") ? parseJSON(decodeBase64(parameters.get("config")?.toString())) : null;

    if (!Object.values(LanguageCode).includes(this.language)) {
      const lang = isBrowser() ? localStorage.getItem('language_code')?.toUpperCase() : '';
      this.language = LanguageCode[lang] || LanguageCode.DE;
    }
    if (!Object.values(CurrencyCode).includes(this.currency)) {
      this.currency = CurrencyCode.EUR;
    }
  }

  public preventLowerValue(): boolean {
    return this.config && (this.config?.lowerValue === false || this.config?.lowerValue === 'false');
  }

  public getConfig(): any {
    return this.config;
  }

  public getAmount(): number {
    const range = RangeValue.amount[this.getCurrency().toLowerCase()];
    if (this.amount || this.amount === 0) {
      this.amount = Math.min(Math.max(this.amount, range.min), range.max);
    }
    return this.amount ? this.amount : null;
  }

  public getSqm(): number {
    const range = RangeValue.area[this.getCurrency().toLowerCase()];
    if (this.sqm || this.sqm === 0) {
      this.sqm = Math.min(Math.max(this.sqm, range.min), range.max);
    }
    return this.sqm ? this.sqm : null;
  }

  public getCo2(): number {
    const range = RangeValue.currency[this.getCurrency().toLowerCase()];
    if (this.co2 || this.co2 === 0) {
      this.co2 = Math.min(Math.max(this.co2, range.min), range.max);
    }

    return this.co2 ? this.co2 : null;
  }

  public getArea(): string {
    return this.area ? this.area : null;
  }

  public CampaginCodes = [
    {
      name: 'rml-mwk',
      code: 'rml-mwk-2',
    },
    {
      name: 'rml-mp',
      code: 'rml-mp2'
    }
  ]

  public getCampaignCode(): string {
    if (!this.campaignCode) {
      return null;
    }

    const special_campaign_code = this.CampaginCodes.find(c => c.name === this.campaignCode);
    return special_campaign_code ? special_campaign_code.code : this.campaignCode;
  }

  public setCampaignCode(value: string) {
    this.campaignCode = value;
  }

  public getInterval(): number {
    return this.interval ? this.interval : null;
  }

  public getLandCode(): string {
    return this.landCode ? this.landCode : null;
  }

  public setLandCode(value: string) {
    this.landCode = value;
  }

  public getRegionCode(): string {
    return this.regionCode ? this.regionCode : null;
  }

  public setRegionCode(value: string) {
    this.regionCode = value;
  }
  public getLand(): any {
    return this.land ? this.land : null;
  }

  public setLand(land: any) {
    localStorage.setItem('land_code', land.code);
    this.land = land;
  }

  public setRegion(region: any) {
    localStorage.setItem('region_code', region.code);
    this.region = region;
  }

  public getStatus(): string {
    return this.status ? this.status : null;
  }

  public getCouponCode(): string {
    return this.couponCode ? this.couponCode : null;
  }

  public getCurrency(): CurrencyCode {
    return this.currency ? this.currency : CurrencyCode.EUR;
  }

  public getLanguage(): LanguageCode {
    return this.language ? this.language : LanguageCode.DE;
  }

  public getTemplateTag(): string {
    return this.tag ? this.tag : null;
  }

  public getRhythm(): string {
    if(this.rhythm) {
      const convertRhythm = this.rhythm?.toLocaleLowerCase();
      return convertRhythm || '0';
    }
  }

  public getGoal(): string {
    return this.goal ? this.goal : null;
  }

  public getSubmissionId(): string {
    return this.submissionId ? this.submissionId : null;
  }

  public getSource(): string {
    return this.source ? this.source : null;
  }
}
